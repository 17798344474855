import React from "react";
import { IContent } from "../contexts/ContentContext";
import { IEvent } from "../components/event/types";
import EventList from "../components/event/EventList";
import Page from "../components/page/Page";
import { eventsMeta } from "./meta";
import "./events.css";

export default ({
  pageContext
}: {
  pageContext: { content: IContent; events: IEvent[] };
}) => {
  const { content, events } = pageContext;
  const currentDate = new Date();
  const nextEvents: IEvent[] = [];
  const pastEvents: IEvent[] = [];

  events
    .sort((eventA, eventB) =>
      new Date(eventA.startDate) > new Date(eventB.startDate) ? -1 : 1
    )
    .forEach(event => {
      const sortedEvents =
        new Date(event.endDate) < currentDate ? pastEvents : nextEvents;
      sortedEvents.push(event);
    });

  return (
    <Page
      content={content}
      seo={{
        description: eventsMeta.description,
        title: eventsMeta.title
      }}
    >
      <section className="events">
        <div className="events-future">
          <EventList events={[...nextEvents].reverse()} title="Next events" />
        </div>
        <div className="events-past">
          <EventList events={pastEvents.slice(0, 10)} title="Past events" />
        </div>
      </section>
    </Page>
  );
};
