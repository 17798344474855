import React, { useMemo } from "react";
import { IEvent } from "./types";
import ContentSection from "../contentSection/ContentSection";
import EventSummary from "./EventSummary";
import FadeinDiv from "../fadeinDiv/FadeinDiv";
import "./event.css";

interface IProps {
  events?: IEvent[];
  showRecent?: boolean;
  title?: string;
}

const EventList: React.FC<IProps> = ({ events, showRecent = false, title }) => {
  const currentDate = new Date();

  const shownEvents = useMemo(
    () =>
      showRecent
        ? events
            ?.filter(event => new Date(event.endDate) > currentDate)
            ?.sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate))
            ?.slice(0, 2)
        : events,
    [events]
  );

  return !!shownEvents?.length ? (
    <FadeinDiv>
      {title && <span className="event-list-title">{title}</span>}
      <div className="event-list">
        {shownEvents.map(event => (
          <EventSummary key={event.id} {...event} />
        ))}
      </div>
    </FadeinDiv>
  ) : (
    <ContentSection
      title={title}
      description1={{ description1: "No events are planned at this time" }}
    />
  );
};

export default EventList;
