import React, { useState } from "react";
import classnames from "classnames";
import { SecondaryButton } from "@metyis-ds/button";
import { Link } from "../Link/LinkWithPrevUrl";
import { IEvent } from "./types";
import { useCheckWindowSize } from "@metyis-ds/hooks";
const getUrl = require("../../helpers/helpers.ts").getUrl;
import FadeinDiv from "../fadeinDiv/FadeinDiv";
import LazyLoadImage from "../image/LazyLoadImage";
import Share from "../../assets/share.svg";
import "./event.css";

const EventSummary: React.FC<IEvent> = ({
  endDate: endDateString,
  image,
  eventLocation,
  showHours,
  startDate: startDateString,
  summary,
  title,
  url
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const isMobile = useCheckWindowSize(1000);
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const startDay = startDate.toLocaleDateString("en", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
  const endDay = endDate.toLocaleDateString("en", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
  const startTime = startDate.toLocaleTimeString("en", {
    minute: "numeric",
    hour: "numeric",
    hour12: false
  });
  const endTime = endDate.toLocaleTimeString("en", {
    minute: "numeric",
    hour: "numeric",
    hour12: false
  });
  const isSingleDay = startDay === endDay;

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleShare = e => {
    if (isMobile && typeof navigator !== "undefined" && navigator.share) {
      e.preventDefault();
      navigator.share({
        title: title,
        text: summary.summary,
        url: `https://metyis.com${url}`
      });
    }
  };

  return (
    <Link to={getUrl("event", url)}>
      <FadeinDiv
        className="event-summary"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="event-img-wrapper">
          <LazyLoadImage
            alt={image.title}
            className="event-img"
            src={image.file.url}
          />
        </div>
        {isMobile && <span className="event-title">{title}</span>}
        <div className="event-details">
          <span className="event-date">
            {isSingleDay ? startDay : `${startDay} - ${endDay}`}
          </span>
          {showHours && (
            <span className="event-time">{`${startTime} - ${endTime}`}</span>
          )}
          <span className="event-location">{eventLocation}</span>
        </div>
        <div className="event-description-wrapper">
          {!isMobile && (
            <div className="event-description-main">
              <span className="event-title">{title}</span>
              <p className="event-description">{summary.summary}</p>
            </div>
          )}
          <div className="event-actions-wrapper">
            <SecondaryButton
              backgroundColor="var(--color-blue-dark)"
              className={classnames({ hover: isHovering })}
              color="var(--color-gray-light)"
            >
              More information
            </SecondaryButton>
            {isMobile && (
              <span className="event-actions">
                <Share className="event-action-button" onClick={handleShare} />
              </span>
            )}
          </div>
        </div>
      </FadeinDiv>
    </Link>
  );
};

export default EventSummary;
